<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="page">
    <ClickedFiltersTopComponent />
    <div v-on:click="changePage" v-bind:class="{
      pointer: $store.state.firstVisit,
      horizontalswipe: $store.state.firstVisit,
    }" class="detailsviewclass" v-if="$store.state.bootingDone">
      <!-- Exlpanation -->
      <!-- <div
        v-if="
          $store.state.firstVisit && !$store.state.detailsExplanationFinished
        "
        class="class1"
        style="pointer-events: none"
      >
        <div class="container containerEnhancement">
          <div class="class2">
            <svg
              v-if="$store.state.explanationState == 0"
              class="cardImage"
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <g><rect fill="none" height="24" width="24" x="0" /></g>
              <g>
                <g>
                  <path
                    d="M20.5,2v2.02C18.18,2.13,15.22,1,12,1S5.82,2.13,3.5,4.02V2H2v3.5V7h1.5H7V5.5H4.09c2.11-1.86,4.88-3,7.91-3 s5.79,1.14,7.91,3H17V7h3.5H22V5.5V2H20.5z"
                  />
                  <g>
                    <path
                      d="M18.89,13.77l-3.8-1.67C14.96,12.04,14.81,12,14.65,12H14l0-4.37c0-1.32-0.96-2.5-2.27-2.62C10.25,4.88,9,6.05,9,7.5 v8.15l-1.87-0.4c-0.19-0.03-1.02-0.15-1.73,0.56L4,17.22l5.12,5.19C9.49,22.79,10,23,10.53,23h6.55c0.98,0,1.81-0.7,1.97-1.67 l0.92-5.44C20.12,15.03,19.68,14.17,18.89,13.77z M18,15.56L17.08,21h-6.55l-3.7-3.78L11,18.11V7.5C11,7.22,11.22,7,11.5,7 S12,7.22,12,7.5v6.18h1.76L18,15.56z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <svg
              v-if="$store.state.explanationState == 1"
              class="cardImage"
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <g>
                <path d="M0,0h24 M24,24H0" fill="none" />
                <path
                  d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
                />
                <path d="M0,0h24v24H0V0z" fill="none" />
              </g>
            </svg>

            <div
              class="class3"
              v-if="$store.state.explanationState == 0"
              v-on:click="store.state.detailsExplanationFinished = true"
            >
              <span>
                {{ $store.state.appTexts.swipe[$store.state.user.countryCode] }}
                <br />
                {{ $store.state.appTexts.tosee[$store.state.user.countryCode] }}
              </span>
            </div>
            <div class="class3" v-if="$store.state.explanationState == 1">
              <span>
                {{
                  $store.state.appTexts.clickfilter[
                    $store.state.user.countryCode
                  ]
                }}
                <br />
                {{
                  $store.state.appTexts.tofilter[$store.state.user.countryCode]
                }}
              </span>
            </div>
          </div>
        </div>
      </div> -->
      <DetailsSwiper class="detailsswiper" />
      <!--Buttons on the bottom-->
      <BottomButtonsDetailsComponent />
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import DetailsSwiper from "@/components/Details/DetailsSwiper.vue";

import DetailCategoriesComponent from "@/components/Details/DetailCategoriesComponent.vue";
import DetailCategoriesSelectorComponent from "@/components/Details/DetailCategoriesSelectorComponent.vue";

import BottomButtonsDetailsComponent from "@/components/Details/BottomButtonsDetailsComponent.vue";
import ClickedFiltersTopComponent from "@/components/Filters/ClickedFiltersTopComponent.vue";

import FilterView from "@/views/FilterView.vue";

export default {
  mounted() {
    this.$store.state.activityViewDetails = true;
    this.$store.state.detailsPageVisited = true;

    console.log("detailsPageVisited = true");
  },
  components: {
    BottomButtonsDetailsComponent,
    DetailCategoriesComponent,
    DetailCategoriesSelectorComponent,
    DetailsSwiper,
    FilterView,
    ClickedFiltersTopComponent,
  },
  data() {
    return {
      //explanationState: 0,
    };
  },
  unmounted() {
    if (this.$store.state.storeActivityListFiltered == this.$store.state.storeActivityListFavorites) {
      console.log("set activityListFiltered from activityListFavorites to activityList")
      this.$store.state.storeActivityListFiltered = this.$store.state.storeActivityList
    }
  },
  methods: {
    changePage: function () {
      if (this.$store.state.firstVisit) {
        this.$store.state.explanationState =
          this.$store.state.explanationState + 1;
        console.log(this.$store.state.explanationState);

        // Set firstVisit to "false"
        if (this.$store.state.explanationState > 0) {
          localStorage.setItem("firstVisit", false);
          localStorage.setItem("detailsExplanationFinished", true);

          this.$store.state.firstVisit = false;
          this.$store.state.detailsExplanationFinished = true;
          console.log("firstVisit is set to false in localStorage and store");
        }
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.horizontalswipe {
  touch-action: pan-x;
}

.detailsswiper {
  min-height: 100vh;
}

.pointer {
  cursor: pointer;
}

.class1 {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 92;
  background-color: #ffffff90;
  pointer-events: none;
}

.cardImage {
  height: 3rem;
  width: 3rem;
  margin-bottom: 1rem;
}

.containerEnhancement {
  position: relative;
  height: 100%;
  width: 100%;
}

.class2 {
  position: absolute;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #007bff;
  padding: 2rem;
  border-radius: 4rem;
  pointer-events: none;
}

.class3 {
  color: white;
}

.class4 {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
  display: unset;
}

.container {
  /* max-width: 50rem; */
}

.details-title {
  z-index: 80;
  font-weight: bold;
  padding: 0.5rem 0.2rem 0.5rem 0.2rem;
  color: #000000;
  width: 100%;
}

.swiper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 1rem;
}
</style>